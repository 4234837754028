import { useApi, useLayerFilter } from '@agritechnovation/api-query';
import type { LayerOptions } from '../../layer-options';
import { MyYieldGeoJsonLayer } from './geojson';
import { MyYieldGeoTIFFLayer } from './geotiff';
import type { LayerFilter, RawLegendEntry } from '@agritechnovation/api';
import {
	convertMfwStyleToGeoStyler,
	getUnit,
	useLayerAvailableLayers
} from '../../helpers';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo } from 'react';
import type { CustomTileLayer } from '@agritechnovation/ol-react';
import { useTileLayer } from '@agritechnovation/ol-react';
import { useFindLayer } from '../../../helpers';
import type { Style } from 'geostyler-style';
import {
	isStaticStyle,
	isTempStaticStyle,
	ProfitabilityAsGeostyler
} from './myyield.utils';
import type { ExpressionValue } from 'ol/expr/expression';

export type MyYieldLayerOptions = LayerOptions & {
	yield: {
		variant: number;
		cost?: number;
		sell?: number;
		staticStyle?: boolean;
		carton?: number;
	};
};
export function MyYieldLayer(props: MyYieldLayerOptions) {
	const { data: layerFilter } = useLayerFilter(props.data.layerFilterId);
	const key = `yield-${layerFilter?.id}-${props.yield.variant}-${props.yield.cost}-${props.yield.sell}-${props.data.dates.join('-')}-${props.data.farmId}-${props.data.dateBucket}`;
	const { data: dates = [], isPending } = useLayerAvailableLayers({
		...props.data,
		variant: props.yield.variant
	});
	const hasStyle = dates.some((d) => d.has_s3_style);
	if (isPending) return null;

	if (!layerFilter) return null;
	if (hasStyle) {
		return (
			<NewMethodYieldLayer
				{...props}
				layerFilter={layerFilter}
				key={`tiff-${key}`}
			/>
		);
	}
	if (layerFilter.layer_type === 'geojson') {
		return (
			<>
				<MyYieldGeoJsonLayer key={key} {...props} />
			</>
		);
	}
	if (layerFilter.layer_type === 'cog') {
		return <MyYieldGeoTIFFLayer {...props} />;
	}
	return null;
}

export type MyYieldS3Style = Array<{
	n_classes: number;
	classes: Array<{
		area_ac: number;
		area_ha: number;
		area_m2: number;
		color: string;
		label: string;
		max: number;
		min: number;
	}>;
}>;

export type StaticMyYieldS3Style = {
	type: 'custom';
	display_as?: 'KG' | 'LB' | 'BUSHEL';
	classes: Array<{
		color: string;
		label: string;
		min: number;
		max: number;
		area_ac: number;
		area_ha: number;
		area_m2: number;
	}>;
};

export type TempStaticMyYieldS3Style = Array<{
	color: string;
	label: string;
	min: number;
	max: number;
	area_ac: number;
	area_ha: number;
	area_m2: number;
}>;

function s3StyleToRawLegend(
	style:
		| MyYieldS3Style[number]['classes']
		| StaticMyYieldS3Style['classes']
		| TempStaticMyYieldS3Style
): RawLegendEntry[] {
	const totalArea = style.reduce((acc, curr) => acc + curr.area_m2, 0);

	return style.map((entry) => ({
		color: entry.color,
		colour: entry.color,
		label: entry.label,
		value: entry.min,
		area: entry.area_m2,
		area_ac: entry.area_ac,
		area_ha: entry.area_ha,
		area_m2: entry.area_m2,
		areaHa: entry.area_ha,
		areaAcres: entry.area_ac,
		areaPercentage: (entry.area_m2 / totalArea) * 100,
		legend: entry.label,
		legend_af: entry.label,
		legend_en: entry.label,
		legend_es: entry.label,
		min: entry.min,
		max: entry.max
	}));
}
function profitStyle({
	cost,
	sell,
	carton,
	maxTons,
	minTons
}: {
	cost: number;
	sell: number;
	carton: number | undefined;
	maxTons: number;
	minTons: number;
}): ExpressionValue {
	// if carton is set, sell needs to be converted to price per ton based on the carton weight
	// carton weight is in kg
	if (carton) {
		const sellingPricePerKG = sell / carton;
		const sellingPricePerTon = sellingPricePerKG * 1000;
		sell = sellingPricePerTon;
	}

	const lossColors = [
		{ label: 'Very High Loss', color: '#b30000' },
		{ label: 'High Loss', color: '#e60000' },
		{ label: 'Medium Loss', color: '#ff1a1a' },
		{ label: 'Small Loss', color: '#ff4d4d' }
	];

	const profitColors = [
		{ label: 'Small Profit', color: '#79de6e' },
		{ label: 'Medium Profit', color: '#3fd02f' },
		{ label: 'High Profit', color: '#2c9321' },
		{ label: 'Very High Profit', color: '#195313' }
	];

	const maxProfit = maxTons * sell - cost;
	const minProfit = minTons * sell - cost;

	const band = ['band', 1];
	const profitBand = ['floor', ['-', ['*', sell, band], cost]];
	const lstep = ['abs', ['/', maxProfit, lossColors.length]];
	const pstep = ['abs', ['/', minProfit, profitColors.length]];
	const losColorString = lossColors.map((p) => p.color);
	const profitColorString = profitColors.map((p) => p.color);

	const pindex = ['floor', ['/', profitBand, pstep]];
	const lindex = ['floor', ['/', profitBand, lstep]];
	const profitPallette = ['palette', pindex, profitColorString];
	const lossPallette = ['palette', lindex, losColorString];

	const alphaBand = ['band', 2];
	const expression = [
		'case',
		// if no data
		['==', alphaBand, 0],
		[0, 0, 0, 0],
		// if profit is negative
		['<', profitBand, 0],
		lossPallette,
		// if profit is positive
		['>', profitBand, 0],
		profitPallette,
		'#000000'
	];

	return expression;
}

function s3StyleToOlStyle(
	style:
		| MyYieldS3Style[number]['classes']
		| StaticMyYieldS3Style['classes']
		| TempStaticMyYieldS3Style
): ExpressionValue | undefined {
	const color = [
		// method
		'interpolate',
		// interpolation type
		['linear'],
		// interpolation value
		['band', 1],
		// color stops
		0,
		[0, 0, 0, 0]
	];
	const pallet = [] as (string | number)[];
	for (const entry of style) {
		pallet.push(entry.min);
		pallet.push(entry.color.toUpperCase());
		pallet.push(entry.max);
		pallet.push(entry.color.toUpperCase());
	}
	const palletPairs = pallet.reduce(
		(acc, curr, i) => {
			if (i % 2 === 0) {
				acc.push([curr as number, pallet[i + 1] as string]);
			}
			return acc;
		},
		[] as [number, string][]
	);
	palletPairs.sort((a, b) => a[0] - b[0]);
	const palletFlat = palletPairs.flatMap((x) => x);
	color.push(...palletFlat);
	if (color.length <= 5) return;
	return color;
}

type MyYieldData = {
	url: string;
	nodata: number;
	normalize: boolean;
	bands: number[];
};

type MyYieldStyle = {
	ol: ExpressionValue;
	merged: RawLegendEntry[];
	style: Style;
	unit: string;
};

function InnerMyYieldLayer(
	opts: MyYieldLayerOptions & {
		layerFilter: LayerFilter;
		sources: MyYieldData[];
		availableLayerId: number;
		style: MyYieldStyle;
	}
) {
	const initialLayer = useFindLayer<CustomTileLayer>(
		(l) => l.get('mfw_uuid') === opts.layer.uniqueId
	);
	const [layer] = useTileLayer({
		initialLayer,
		zIndex: opts.render.zIndex,
		data: opts.sources,
		style: opts.style.ol,
		opacity: opts.render.opacity,
		properties: {
			mfw_uuid: opts.layer.uniqueId,
			farmId: opts.data.farmId,
			layerFilterId: opts.data.layerFilterId,
			dates: opts.data.dates,
			// the backend seems not to care most of the time, it seems it just uses the al to determine the farm id, layerfilter id and dates
			// but we sometimes need to pass it to the backend from the layer properties (e.g. LayerInfo component)
			available_layer_id: opts.availableLayerId,
			geoStylerStyle: opts.style?.style,
			type: 'cog'
		}
	});
	useEffect(() => {
		layer.set('geoStylerStyle', opts.style?.style);
	}, [layer, opts.style?.style]);
	return null;
}
function NewMethodYieldLayer(
	opts: MyYieldLayerOptions & { layerFilter: LayerFilter }
) {
	const { data: dates = [] } = useLayerAvailableLayers({
		...opts.data
	});
	const api = useApi();
	const uuid = dates.at(0)?.uuid;
	const classification = useMemo(() => {
		const al = dates.at(0);
		if (!al) return;
		return al.uuid;
	}, [dates]);

	const availableLayerId = dates.at(0)?.id;
	const data = useMemo(() => {
		if (!uuid) return [];
		return [
			{
				url: api.tms.createURL(`/${uuid}/raw.tiff`),
				nodata: -9999,
				normalize: false,
				bands: [1]
			}
		];
	}, [api.tms, uuid]);

	const TON_TO_KG = 1000;
	const TON_TO_LB = 2204.62;
	const TON_TO_BUSHEL = 36.7437;

	const styleSelector = useCallback(
		(
			styles: MyYieldS3Style | StaticMyYieldS3Style | TempStaticMyYieldS3Style
		) => {
			let classes: MyYieldS3Style[number]['classes'] | StaticMyYieldS3Style;
			if (isStaticStyle(styles)) {
				classes = styles.classes.map((c, i) => {
					let label = c.label;
					let convertedMax = c.max;
					let convertedMin = c.min;
					if (styles.display_as?.toLowerCase() === 'lb') {
						convertedMax = c.max * TON_TO_LB;
						convertedMin = (c.min ?? 0) * TON_TO_LB;
					} else if (styles.display_as?.toLowerCase() === 'bushel') {
						convertedMax = c.max * TON_TO_BUSHEL;
						convertedMin = (c.min ?? 0) * TON_TO_BUSHEL;
					} else if (styles.display_as?.toLowerCase() === 'kg') {
						convertedMax = c.max * TON_TO_KG;
						convertedMin = c.min * TON_TO_KG;
					} else {
						convertedMax = c.max * TON_TO_KG;
						convertedMin = c.min * TON_TO_KG;
					}
					if (i === 0) {
						label = `< ${convertedMax}`;
					} else if (i === styles.classes.length - 1) {
						label = `> ${convertedMin}`;
					} else {
						label = `≥ ${convertedMin} < ${convertedMax}`;
					}

					return {
						...c,
						label
					};
				});
			} else if (isTempStaticStyle(styles)) {
				classes = styles;
			} else {
				let style = styles.find((s) => s.n_classes === opts.yield.variant);
				if (!style) {
					const maxClass = Math.max(...styles.map((s) => s.n_classes));
					style = styles.find((s) => s.n_classes === maxClass);
				}
				if (!style) throw new Error('No style found');
				classes = style.classes;
			}
			if (!classes) throw new Error('No style found');
			const merged = s3StyleToRawLegend(classes);
			const { cost = 0, sell = 0, carton } = opts.yield;
			const maxTons = merged.at(-1)?.max ?? 0;
			const minTons = merged.at(0)?.min ?? 0;
			if (cost !== 0 && sell !== 0) {
				return {
					ol: profitStyle({ cost, sell, maxTons, minTons, carton }),
					merged,
					style: ProfitabilityAsGeostyler(),
					unit: 'Profitability'
				};
			}
			return {
				ol: s3StyleToOlStyle(classes),
				merged,
				style: convertMfwStyleToGeoStyler(merged),
				unit: getUnit(merged, opts.layerFilter)
			};
		},
		[opts.layerFilter, opts.yield]
	);

	const { data: style, error } = useQuery({
		queryKey: ['s3-tiff-style', classification, !!opts.yield.staticStyle],
		queryFn: async () => {
			if (opts.yield.staticStyle) {
				const staticStyle = await api.tms
					.get<StaticMyYieldS3Style>(
						`/${classification}/style-static-temp.json`,
						'json'
					)
					.catch(() => null);
				if (staticStyle) return staticStyle;
			}
			return api.tms.get<MyYieldS3Style>(
				`/${classification}/style.json`,
				'json'
			);
		},
		select: styleSelector,
		enabled: !!classification
	});

	if (error) {
		throw error;
	}

	if (!style) return null;
	if (!style.ol) return null;

	return (
		<InnerMyYieldLayer
			{...opts}
			sources={data}
			style={style as MyYieldStyle}
			availableLayerId={availableLayerId as number}
		/>
	);
}
